import React, { useState } from 'react';
import styled from '@emotion/styled';

import CloseIcon  from '@material-ui/icons/Close';

import { breakpoints, colors, spacing, radius } from '../utils/styles';
import CartContext, { ExtendedProduct } from '../data/cart-context';
import { Product } from '../data/product-model';
import { theme } from '../common/site-variables';
import { Button } from '@material-ui/core';
import { apiUrl } from '../services/auth-service';

const CartListItemRoot = styled('li')`
  align-items: center;
  border-bottom: 1px solid ${theme.cs_yellow};
  display: flex;
  justify-content: space-between;
  padding: ${spacing.md}px 0;
`;

const CartThumbnail = styled(`img`)`
  border: 1px solid ${theme.cs_lighter_blue};
  border-radius: ${radius.default}px;
  height: 36px;
  width: 36px;
`;

const Thumbnail = styled(CartThumbnail)`
  flex-grow: 0;
  margin-left: ${spacing['2xs']}px;
  margin-right: ${spacing.sm}px;
`;

const Info = styled('div')`
  flex-grow: 1;
`;

const Name = styled('span')`
  display: block;
  font-size: 1rem;
  line-height: 1.2;
`;

const Meta = styled('span')`
  color: ${theme.cs_yellow};
  display: block;
  font-size: 0.95rem;
  font-style: normal;
`;

const Input = styled(`input`)`
  background-color: ${theme.cs_lighter_blue};
  border: 1px solid ${theme.cs_blue};
  border-radius: ${radius.default}px;
  color: ${theme.cs_dark_blue};
  display: block;
  font-size: 1.1rem;
  padding: ${spacing.sm}px ${spacing.md}px;
  width: 100%;

  :focus {
    box-shadow: 0 0 0 3px ${colors.accent};
    outline: 0;
    transition: box-shadow 0.15s ease-in-out;
  }
`;

const Quantity = styled(Input)`
  flex-grow: 0;
  height: 44px;
  margin-right: ${spacing.xs}px;
  padding: 0 ${spacing.xs}px 0;
  text-align: center;
  width: 50px;

  @media (min-width: ${breakpoints.desktop}px) {
    width: 70px;
  }
`;

const Remove = styled(Button)`
  border: 1px dotted ${colors.textLighter};
  display: flex;
  height: 44px;
  justify-content: center;
  margin-right: ${spacing['2xs']}px;
  padding: 0;
  width: 44px;
  color: ${theme.cs_lighter_blue};

  svg {
    height: 24px;
    margin: 0;
    width: 24px;
  }
`;

interface PropTypes {
  product: ExtendedProduct;
  removeProduct: (product: ExtendedProduct) => void;
  updateQuantity: (product: ExtendedProduct, quantity: number) => void;
}

export default (props: PropTypes) => {
  const { product, removeProduct, updateQuantity } = props;
  const cartState = React.useContext(CartContext);
  const [curQuantity, setQuantity] = useState(product.quantity);


  const handleInputChange = event => {
    const value = event.target.value;

    // Make sure the quantity is always at least 1.
    const safeValue = Math.max(Number(value), 0);

    if (value === product.quantity) {
      return;
    }

    if (value === '') {

      return;
    }


    // If the quantity is set to 0, remove the item.
    if (safeValue === 0) {
      removeProduct(product);
      return;
    }

    // If we get here, update the quantity.
    updateQuantity(product, safeValue - product.quantity); 

  };

  const handleRemoveItem = event => {
    removeProduct(product);
  };

  return (
    <CartListItemRoot>
      <Thumbnail
        src={(product.images && product.images.length > 0) && `${apiUrl}/assets/${product.images[0].directus_files_id}`}
        alt="No Image"
      />
      <Info>
        <Name>{product.title}</Name>
        <Meta>
          ${product.price}
        </Meta>
      </Info>
      <Quantity
        aria-label="Quantity"
        id={`quantity_${product.id}`}
        type="number"
        name="quantity"
        min="1"
        onChange={event => handleInputChange(event)}
        value={product.quantity}
      />
      <Remove onClick={handleRemoveItem}>
        <CloseIcon />
      </Remove>
    </CartListItemRoot>
  );
};
