import React from 'react';
import styled from '@emotion/styled';
import CartListItem from './CartListItem';

import { colors, spacing } from '../utils/styles';
import { Product } from '../data/product-model';
import { CartContext, ExtendedProduct } from '../data/cart-context';
import { theme } from '../common/site-variables';


const CartListRoot = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Headers = styled(`div`)`
  border-bottom: 1px solid ${colors.brandBright};
  display: flex;
  justify-content: space-between;

  span {
    color: ${theme.cs_yellow};
    flex-basis: 60px;
    flex-grow: 0;
    font-size: 0.8rem;
    padding-bottom: ${spacing.xs}px;
    text-align: center;

    &:first-of-type {
      flex-grow: 1;
      text-align: left;
    }
  }
`;

interface PropTypes {
  cartState: CartContext;
}


const CartList = (props: PropTypes) => {
  const { cartState } = props;
  const products: ExtendedProduct[] = cartState.cartProducts;

  const handleRemoveProduct = (product: Product) => {
    cartState.updateCartProducts(product, false, true);
    console.log('removed from list');
  }

  const handleUpdateQuantity = (product: Product, quantity: number)  => {
    cartState.updateCartProducts(product, true, false, quantity);
  }



  return (
    <>
      <Headers>
        <span>Product</span>
        <span>Qty.</span>
        <span>Remove</span>
      </Headers>
      <CartListRoot>
        {products.map(product => (
          <CartListItem
            key={product.id}
            product={product}
            removeProduct={handleRemoveProduct}
            updateQuantity={handleUpdateQuantity}
          />
        ))}
      </CartListRoot>
    </>
  );
}

export default CartList;
